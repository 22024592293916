import { IMG, SITUATION_FOCUS } from "constant";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// SITUATION_FOCUS
const Focus = () => {
  const { pathname } = useLocation();
  const [currentHeading, setCurrentHeading] = useState(pathname.split("/")[2]); // TODO;; 리펙토링 필요
  const [data, setData] = useState(SITUATION_FOCUS[currentHeading]);

  useEffect(() => {
    setData(SITUATION_FOCUS[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [currentHeading]);
  useEffect(() => {
    setData(SITUATION_FOCUS[currentHeading]);
    setCurrentHeading(pathname.split("/")[2]);
  }, [pathname]);
  return (
    <section className="section situation full">
      <div className="focus">
        <div className="focus-inner">
          <div className="contents-wrap">
            <div className="head-title">
              <p className="title">{data.title}</p>
              <span className="sub-title">{data.subTitle}</span>
            </div>

            <ul className="img-step-list">
              {data.imgList.map((imgStepItem, imgStepIdx) => {
                return (
                  <li className="img-step-item">
                    <div className="top">
                      <img src={imgStepItem.src} alt="" />
                      {data.imgList.length !== 1 && <div className="badge">{"0" + (imgStepIdx + 1)}</div>}
                    </div>
                    <div className="bottom">
                      <p className="text">{imgStepItem.text}</p>
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="way-text">
              <p className="title">집중하세요.</p>
              <span className="gradient-line"></span>
              <span className="sub-title">
                앞으로 여러분에게 어떤 일이 벌어지고,
                <br />
                <strong>어떻게 대처</strong>해야 하는지,
                <br />
                <strong>법무법인 온강 마약센터만</strong>의
              </span>
            </div>

            <div className="text-banner">
              <strong>‘{data.textBanner}’</strong> 모두 공개합니다.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Focus;
