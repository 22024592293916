import Styles from "./styles";
import { forwardRef } from "react";
// swiper
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useBoard } from "hooks";

const TopBanner = forwardRef(({ scroll }, ref) => {

  const boardInfo = useBoard({
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "122",
      type: 12,
      recordSize: 10,
    }
  });
  const list = boardInfo.listQuery?.data?.list || [];

  if(list.length == 0)return <></>;

  return (
    <Styles.Container ref={ref} className={scroll ? "active" : ""}>
      <Swiper
        direction={"vertical"}
        speed={700}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {list.map((item, index) => {
          return (
            <SwiperSlide key={"topBannerList" + index}>{item.title}</SwiperSlide>
          );
        })}
      </Swiper>
    </Styles.Container>
  );
});
export default TopBanner;
