import { atom } from "jotai";

const defaultModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });
const termsModalAtom = atom({ show: false, zIndex: 20 });
const imageModalAtom = atom({ show: false, zIndex: 20 });

export { defaultModalAtom, toastModalAtom, termsModalAtom, imageModalAtom };

export default {
  defaultModalAtom,
  toastModalAtom,
  termsModalAtom,
  imageModalAtom,
};
