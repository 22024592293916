import { IMG, SPECIAL_DATA } from "constant";
import React from "react";
import { useDeviceType } from "hooks/useMediaQuery";

const SpecialItem = ({ id, headText, subText, img, mbImg }) => {
  const deviceType = useDeviceType();

  return (
    <>
      <div className="item-wrap">
        <div className="count-num">{id}</div>
        <div className="head">
          <h3 className="heading">{headText}</h3>
          <h4 className="sub-text">{subText}</h4>
        </div>
      </div>

      {img && (
        <div className="imgWrap">
          <img src={deviceType === "mobile" ? mbImg : img} alt="" />
        </div>
      )}
    </>
  );
};

const IntroSpecial = () => {
  return (
    <section className="section special full">
      <div className="special-wrap">
        <div className="special-banner">
          <div className="text-wrap">
            <h4>절박한 상황에 처한 의뢰인에게 </h4>
            <h3>
              신속한 <strong> 일상 복귀</strong>를<br />
              도와드리겠습니다.
            </h3>
            <h4 className="bottom">
              법무법인 온강을 선택해야 하는
              <span className="high-light">5가지 이유</span>
              반드시 읽어주세요.
            </h4>
          </div>
        </div>
        <div className="special-reason">
          <ul className="reason-list">
            <li className="reason-item">
              <SpecialItem
                id={SPECIAL_DATA[0].id}
                headText={SPECIAL_DATA[0].text}
                subText={SPECIAL_DATA[0].subText}
                img={SPECIAL_DATA[0].img}
                mbImg={SPECIAL_DATA[0].mbImg}
              />
            </li>
            <li className="reason-item">
              <SpecialItem
                id={SPECIAL_DATA[1].id}
                headText={SPECIAL_DATA[1].text}
                subText={SPECIAL_DATA[1].subText}
              />
            </li>
            <li className="reason-item full">
              <div className="lecture-box">
                <img src={IMG.SPECIAL_LECTURE} alt="강의 이미지" />
              </div>
            </li>

            <li className="reason-item full bg">
              <div className="inner-flex">
                <SpecialItem
                  id={SPECIAL_DATA[2].id}
                  headText={SPECIAL_DATA[2].text}
                  subText={SPECIAL_DATA[2].subText}
                />
                <SpecialItem
                  id={SPECIAL_DATA[3].id}
                  headText={SPECIAL_DATA[3].text}
                  subText={SPECIAL_DATA[3].subText}
                />
              </div>
              <img
                className="counselor-img"
                src={IMG.SPECIAL_COUNSELOR}
                alt=""
              />
            </li>

            <li className="reason-item">
              <SpecialItem
                id={SPECIAL_DATA[4].id}
                headText={SPECIAL_DATA[4].text}
                subText={SPECIAL_DATA[4].subText}
              />
              <img className="hair-img" src={IMG.SPECIAL_HAIR} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default IntroSpecial;
