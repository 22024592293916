import { useState, useEffect } from "react";
import { InputSearch } from "components/ui/input";
import { Pagination } from "components/layout";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from './styles';

// img
import SearchImg from "resources/image/search/search_img.jpg";
import { useBoard } from "hooks";
import utils from "utils";
import { IMAGEPATH } from "service/connector";

function SearchList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      keyword: param.keyword ? param.keyword : "",
      parent_category_info_id: "33",
      category_info_id: "",
      type: 2,
      recordSize: 10,
    };
    setFilterInfo({ ...f });
    setSearch(param.keyword ? param.keyword : "");
  }, [location]);

  const boardInfo = useBoard({
    listOption: filterInfo
  });
  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <Styles.SearchList>
      <InputSearch
        placeholder="성공사례 검색"
        value={search}
        setValue={setSearch}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyUp={()=>{ navigate(utils.etc.getQueryParamUrl({ keyword : search, page : 1, disableScrollTop : true }, true)) }}
        onClick={() => {  navigate(utils.etc.getQueryParamUrl({ keyword : search, page : 1, disableScrollTop : true }, true)) }}
      />
      <div className="search_wrap">
        <ul className="search_list">
          {
            (list).length > 0 ?
            (list).map((value,index)=>{
              return (
                <li className="search_row" key={'searchList' + index} onClick={() => {navigate(`/example/detail/${value.id}`);}}>
                  <img src={IMAGEPATH + value.image} className="search_img" alt="" />
                  <div className="search_content">
                    <span className="tag">{value.keyword}</span>
                    <div className="title_box">
                      <p className="search_tit">{value.title}</p>
                      <div className="date_box">
                        <b>종결일</b>
                        <span className="date">{ utils.date.getDateType(value.ended_at,'-') }</span>
                        <span className="group">{value.subtitle}</span>
                      </div>
                    </div>
                    <p className="dec">{value.description_text?.slice(0, 200)}</p>
                  </div>
                </li>
              )
            })
            :
            <li className="none_list">검색결과가 없습니다.</li>
          }
        </ul>
      </div>

      <Pagination
        pagination={boardInfo.listQuery?.data?.pagination}
        page={boardInfo.listQuery?.data?.pagination?.page}
        list={boardInfo.listQuery?.data?.pagination?.page_list}
        maxPage={boardInfo.listQuery?.data?.pagination?.totalPageCount}
      />
    </Styles.SearchList>
  );
}

export default SearchList;