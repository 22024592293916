import Styles from "./styles";

// img
import IconSearch from "resources/image/icon/icon_search.svg";

function InputSearch({
  placeholder,
  value,
  onChange,
  onClick,
  onKeyUp,
  black,
  tag
}) {
  return (
    <Styles.InputSearch>
      <input
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={(e)=>{
          if (e.key == "Enter") {
            onKeyUp && onKeyUp();
          }
        }}
        autoComplete="off"/>
        <button onClick={onClick}><img src={IconSearch} alt="검색" /></button>
    </Styles.InputSearch>
  );
}

export { InputSearch };

export default InputSearch;
