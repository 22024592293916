import styled, { css } from "styled-components";

// img
import CounselBg from "resources/image/main/counsel_bg.jpg";

const MainCounsel = styled.div`
  position: relative;
  width: 100%;
  padding: 92px 0;
  background: #fff url(${CounselBg}) no-repeat top center / 1920px;
  overflow: hidden;
  & .counsel_inner {
    display: flex;
    align-items: center;
    width: 1260px;
    margin: 0 auto;
    & .counsel_title {
      width: 638px;
      & .tit {
        font-size: 56px;
        line-height: 72px;
        font-weight: 400;
        letter-spacing: -0.5px;
        margin-bottom: 30px;
        word-wrap: break-word;
        word-break: keep-all;
        & strong {
          font-weight: 800;
          color: #da250b;
        }
      }
      & .text {
        font-size: 26px;
        line-height: 36px;
        font-weight: 500;
        letter-spacing: -0.5px;
        & span {
          color: #da250b;
        }
        & br {
          display: none;
        }
      }
      & .dec {
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
        font-weight: 500;
        color: #888;
        margin-bottom: 10px;
      }
      & strong {
        font-weight: 800;
        color: black;
      }
    }
    & .counsel_form {
      border: 1px solid #c4c4c4;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw * (92 / 1520)) 0;
    background-size: calc(100vw * (1920 / 1520));
    & .counsel_inner {
      width: calc(100vw * (1260 / 1520));
      & .counsel_title {
        width: calc(100vw * (638 / 1520));
        & .tit {
          font-size: calc(100vw * (56 / 1520));
          line-height: calc(100vw * (72 / 1520));
          margin-bottom: calc(100vw * (30 / 1520));
        }
        & .text {
          font-size: calc(100vw * (26 / 1520));
          line-height: calc(100vw * (36 / 1520));
        }
        & .dec {
          font-size: calc(100vw * (16 / 1520));
          line-height: calc(100vw * (22 / 1520));
          margin-top: calc(100vw * (10 / 1520));
          margin-bottom: calc(100vw * (10 / 1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw * (30 / 375)) 0;
    background-size: calc(100vw * (1920 / 375));
    & .counsel_inner {
      display: block;
      width: 100%;
      padding: 0 calc(100vw * (20 / 375));
      & .counsel_title {
        width: 100%;
        & .tit {
          text-align: center;
          font-size: calc(100vw * (36 / 375));
          line-height: calc(100vw * (47 / 375));
          margin-bottom: calc(100vw * (15 / 375));
        }
        & .text {
          text-align: center;
          font-size: calc(100vw * (18 / 375));
          line-height: calc(100vw * (25 / 375));
          & br {
            display: block;
          }
        }
        & .dec {
          text-align: center;
          font-size: calc(100vw * (14 / 375));
          line-height: calc(100vw * (20 / 375));
          margin-top: calc(100vw * (10 / 375));
          margin-bottom: calc(100vw * (30 / 375));
        }
      }
    }
  }
`;

export { MainCounsel };

export default {
  MainCounsel,
};
