import useUploadImage from './useUploadImage';
import useFiles from './useFiles';
import useCalendar from './useCalendar';
import useModals from './useModals';
import useLoader from './useLoader';
import useBoard from './useBoard';
import useSite from './useSite';
import useInquiry from './useInquiry';
import usePopup from './usePopup';
import useCategory from './useCategory';


export {
    useUploadImage,
    useFiles,
    useCalendar,
    useModals,
    useLoader,
    useBoard,
    useSite,
    useInquiry,
    usePopup,
    useCategory,
}

export default {
    useUploadImage,
    useFiles,
    useCalendar,
    useModals,
    useLoader,
    useBoard,
    useSite,
    useInquiry,
    usePopup,
    useCategory,
}