import Styles from "./styles";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// img
import IconTel from "resources/image/icon/icon_tel_white02.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";
import IconCounsel from "resources/image/icon/icon_counsel.svg";
import { useBoard } from "hooks";

function MbBottomQuickBar({ scroll }) {
  const [visibleBars, setVisibleBars] = useState(true);
  const [hour, setHour] = useState("HH");
  const [seconds, setSeconds] = useState("MM");

  const boardInfo = useBoard({
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "118",
      type: 11,
      recordSize: 10,
    },
  });
  const list = boardInfo.listQuery?.data?.list || [];
  const bottomDetail = list[0];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleBars(false);
      setTimeout(() => {
        setVisibleBars(true);
      }, 100);
    }, 2100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHour(String(new Date().getHours()).padStart(2, "0"));
      setSeconds(String(new Date().getMinutes()).padStart(2, "0"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const buttonList = [
    {
      class: "tel",
      icon: IconTel,
      text: "전화 상담",
      exportLink: "tel:1670-5901",
    },
    {
      class: "kakao",
      icon: IconKakao,
      text: "카톡 상담",
      exportLink: "https://pf.kakao.com/_UHiqxj",
    },
    {
      class: "counsel",
      icon: IconCounsel,
      text: "상담 신청",
      link: "/counsel?type=1&focus=counsel_tab",
    },
  ];

  return (
    <Styles.Container className={scroll ? "active" : ""}>
      {buttonList.map((item, index) => {
        return (
          <div className={"linkWrap " + item.class} key={"link" + index}>
            {item.link ? (
              <Link to={item.link}>
                <div className="icon">
                  <img src={item.icon} alt="linkIcon" />
                </div>
                {item.text}
              </Link>
            ) : (
              <a href={item.exportLink} target="_blank">
                <div className="icon">
                  <img src={item.icon} alt="linkIcon" />
                </div>
                {item.text}
              </a>
            )}
          </div>
        );
      })}

      {bottomDetail && (
        <div className="stateContainer">
          <div className="couselState">
            <div className="icon">
              {Array(5)
                .fill()
                .map((item, index) => {
                  return (
                    visibleBars && (
                      <div className={"bar"} key={"bar" + index}></div>
                    )
                  );
                })}
            </div>
            {bottomDetail?.title}
          </div>
          <div className="time">
            현재 {hour} <span>:</span> {seconds}
          </div>
          <div className="stateText">{bottomDetail?.subtitle}</div>
        </div>
      )}
    </Styles.Container>
  );
}

export default MbBottomQuickBar;
