import React from "react";

import FloatHeading from "components/layout/FloatHeading";
import { ICON, IMG, INTRO_HEADING_DATA, RESUME_DATA } from "constant";
import ArticleHeading from "components/layout/ArticleHeading";
import { ExampleSlide } from "components/layout";
import { useBoard } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGEPATH } from "service/connector";

const IntroExertDetailPage = () => {
  const navigate = useNavigate();
  const param = useParams();

  const boardInfo = useBoard({
    detailOption: {id : param?.id}
  });

  const detail = boardInfo.detailQuery?.data?.data || {};


  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={INTRO_HEADING_DATA} />

        <section className="section full expert detail">
          <div className="expert-detail">
            <div className="expert-detail__inner">
              <div className="text-wrap">
                <div className="top">
                  <div className="badge">{detail?.subtitle}</div>
                  <div className="name-wrap">
                    {detail?.extra_image && <img className="logo-img" src={IMAGEPATH + detail?.extra_image} alt="" />}
                    <p className="title">{detail?.title}</p>
                    {detail?.subtitle?.includes("변호사") || detail?.subtitle?.includes("검사") || detail?.subtitle?.includes("법무실장")  ? <p className="sub-title">변호사</p> : ""}
                  </div>
                </div>
                <div className="bottom">
                  <ul className="inner-scroll-list">
                    {RESUME_DATA.map((resumeItem, resumeIdx) => {
                      return (
                        <li className="scroll-item" key={resumeIdx}>
                          <p className="category">{resumeItem.category}</p>
                          <ul className="resume-list" dangerouslySetInnerHTML={{__html : resumeIdx == 0 ? detail?.description : detail?.subdescription}}></ul>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="img-box">
                <img className="img" src={IMAGEPATH + detail?.image  || IMG.ROYAL_DETAIL_EX} alt="" />
              </div>
            </div>
          </div>
          <div className="expert-detail example">
            {detail?.relate_list?.length > 0 && <div className="example-inner">
              <ArticleHeading title={detail?.title +  " 변호사 성공사례"}>
                <button className="btn-more" onClick={()=>{ navigate("/example");}}>
                  더보기
                  <img src={ICON.ICON_PLUS_BK} alt="" />
                </button>
              </ArticleHeading>
              <ExampleSlide list={detail?.relate_list || []}/>
            </div>}
            <div className="btn-box">
              <button type="button" className="btn" onClick={() => { window.history.back(); }}>
                목록으로 돌아가기
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IntroExertDetailPage;
