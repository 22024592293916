import styled, { css } from "styled-components";

const MainReview = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: #fff;
  overflow: hidden;
  &.active {
    & .tab_menu {
      & .handwriting {
        animation: fadeRight 0.8s ease-in forwards;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 650px;
    height: 472px;
    background: linear-gradient(150deg, #da250b 18.5%, #741406 98.24%);
  }
  & .review_inner {
    display: flex;
    align-items: flex-start;
    width: 1260px;
    height: 512px;
    margin: 0 auto;
    & .tab_contents {
      cursor: pointer;
      position: relative;
      width: 620px;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & .tab_text_box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 50px 30px;
        background: linear-gradient(
          0deg,
          #000 -11.36%,
          rgba(0, 0, 0, 0) 66.86%
        );
        & .tit {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: -0.3px;
          font-weight: 800;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        & .dec {
          font-size: 16px;
          line-height: 19px;
          color: #c4c4c4;
          margin-top: 14px;
          letter-spacing: -0.3px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    & .tab_menu {
      display: flex;
      flex-direction: column;
      width: calc(100% - 620px);
      height: 100%;
      padding-left: 60px;
      & .review_tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & h2 {
          font-size: 56px;
          font-weight: 800;
          letter-spacing: -0.5px;
        }
      }
      & .handwriting {
        margin-top: 30px;
        opacity: 0;
        & img {
          width: 40px;
          height: 32px;
          margin-bottom: 10px;
        }
        & p {
          font-size: 26px;
          line-height: 34px;
          color: #888;
          font-family: "KimjungchulScript-Bold";
          letter-spacing: -0.8px;
          &.quot {
            text-align: right;
            margin-top: 30px;
          }
        }
      }
      & .tab_btn {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: auto;
        & li {
          position: relative;
          width: 180px;
          height: 157px;
          cursor: pointer;
          &::after {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            border: 3px solid #da250b;
            box-sizing: border-box;
          }
          & img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.5s;
            filter: grayscale(100%);
            opacity: 0.3;
          }
          &.active {
            & img {
              filter: none;
              opacity: 1;
            }
            &::after {
              content: "";
            }
          }
        }
      }
    }
  }

  & .btn_more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 154px;
    height: 45px;
    margin: 40px auto 0;
    padding: 0 30px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    border: 1px solid #111;
    transition: 0.5s;
    z-index: 1;
    & img {
      width: 18px;
      height: 18px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      & img {
        animation: rotate 0.8s ease-in-out forwards;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw * (100 / 1520)) 0;
    &::before {
      width: calc(100vw * (650 / 1520));
      height: calc(100vw * (472 / 1520));
    }
    & .review_inner {
      width: calc(100vw * (1260 / 1520));
      height: calc(100vw * (512 / 1520));
      & .tab_contents {
        width: calc(100vw * (620 / 1520));
        & .tab_text_box {
          padding: calc(100vw * (50 / 1520)) calc(100vw * (30 / 1520));
          & .tit {
            font-size: calc(100vw * (18 / 1520));
            line-height: calc(100vw * (27 / 1520));
          }
          & .dec {
            font-size: calc(100vw * (16 / 1520));
            line-height: calc(100vw * (19 / 1520));
            margin-top: calc(100vw * (14 / 1520));
          }
        }
      }
      & .tab_menu {
        width: calc(100% - calc(100vw * (620 / 1520)));
        padding-left: calc(100vw * (60 / 1520));
        & .review_tit {
          & h2 {
            font-size: calc(100vw * (56 / 1520));
          }
        }
        & .handwriting {
          margin-top: calc(100vw * (30 / 1520));
          & img {
            width: calc(100vw * (40 / 1520));
            height: calc(100vw * (32 / 1520));
            margin-bottom: calc(100vw * (10 / 1520));
          }
          & p {
            font-size: calc(100vw * (26 / 1520));
            line-height: calc(100vw * (34 / 1520));
            &.quot {
              margin-top: calc(100vw * (30 / 1520));
            }
          }
        }
        & .tab_btn {
          gap: calc(100vw * (20 / 1520));
          & li {
            width: calc(100vw * (180 / 1520));
            height: calc(100vw * (157 / 1520));
          }
        }
      }
    }

    & .btn_more {
      width: calc(100vw * (154 / 1520));
      height: calc(100vw * (45 / 1520));
      margin: calc(100vw * (40 / 1520)) auto 0;
      padding: 0 calc(100vw * (30 / 1520));
      font-size: calc(100vw * (18 / 1520));
      border-radius: calc(100vw * (30 / 1520));
      & img {
        width: calc(100vw * (18 / 1520));
        height: calc(100vw * (18 / 1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw * (80 / 375)) calc(100vw * (20 / 375));

    &::before {
      top: calc(100vw * (281 / 375));
      width: calc(100vw * (243 / 375));
      height: calc(100vw * (176 / 375));
    }
    & .review_inner {
      position: relative;
      flex-direction: column-reverse;
      width: 100%;
      height: auto;
      padding-bottom: calc(100vw * (122 / 375));
      margin: 0 auto;
      & .tab_contents {
        width: 100%;
        margin-top: calc(100vw * (70 / 375));
        & .tab_text_box {
          padding: calc(100vw * (20 / 375)) calc(100vw * (10 / 375));
          & .tit {
            font-size: calc(100vw * (16 / 375));
            line-height: calc(100vw * (24 / 375));
            font-weight: 800;
          }
          & .dec {
            font-size: calc(100vw * (14 / 375));
            line-height: calc(100vw * (17 / 375));
            margin-top: calc(100vw * (10 / 375));
          }
        }
      }
      & .tab_menu {
        width: 100%;
        padding-left: 0;
        & .review_tit {
          display: block;
          & h2 {
            font-size: calc(100vw * (36 / 375));
            text-align: center;
          }
        }
        & .handwriting {
          margin-top: calc(100vw * (20 / 375));
          & img {
            width: calc(100vw * (30 / 375));
            height: calc(100vw * (24 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }
          & p {
            font-size: calc(100vw * (18 / 375));
            line-height: calc(100vw * (23 / 375));
            &.quot {
              margin-top: calc(100vw * (15 / 375));
            }
          }
        }
        & .tab_btn {
          position: absolute;
          bottom: 0;
          left: 0;
          gap: calc(100vw * (10 / 375));
          & li {
            width: calc(100vw * (105 / 375));
            height: calc(100vw * (92 / 375));
            &.active {
              border: 2px solid #da250b;
            }
          }
        }
      }
    }

    & .btn_more {
      width: calc(100vw * (119 / 375));
      height: calc(100vw * (39 / 375));
      margin: calc(100vw * (20 / 375)) auto 0;
      padding: 0 calc(100vw * (20 / 375));
      font-size: calc(100vw * (16 / 375));
      border-radius: calc(100vw * (30 / 375));
      & img {
        width: calc(100vw * (18 / 375));
        height: calc(100vw * (18 / 375));
      }
    }
  }
  @keyframes fadeRight {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export { MainReview };

export default {
  MainReview,
};
