import ArticleHeading from "components/layout/ArticleHeading";
import CategorySwiperWrap from "components/layout/CategorySwiperWrap";
import ImgBox from "components/layout/ImgBox";
import MoreBtn from "components/layout/MoreBtn";
import { IMG, ICON, STORY_CATEGORY_SWIPER_LIST } from "constant";
import { useBoard, useSite } from "hooks";
import React, { useEffect, useState } from "react";

const StoryYoutube = () => {
  const [mainItem, setMainItem] = useState({});
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    pageSize: 5,
    parent_category_info_id: "50",
    category_info_id: "",
    type: 3,
    recordSize: 10,
  });

  const siteInfo = useSite({
    listOption: {
      board_type: 10,
      content_type: 3,
      type: 2,
    },
  });

  const bestList = siteInfo.listQuery?.data?.list || [];

  useEffect(() => {
    if(mainItem?.id != bestList[0]?.id)
    setMainItem(bestList[0] || {});
  }, [bestList]);

  const boardInfo = useBoard({
    groupListOption: filterInfo,
  });
  const list = boardInfo.groupListQuery?.data?.data || [];

  return (
    <section className="section story youtube full">
      <div className="youtube-wrap">
        <div className="youtube-top">
          <ArticleHeading title={"BEST 영상"} />

          <div id="best_video" className="best-wrap">
            <ImgBox src={`https://img.youtube.com/vi/${mainItem?.subtitle}/maxresdefault.jpg` || IMG.STORY_SW_EX_IMG} />
            <div className="text-wrap">
              <p className="title">{mainItem?.title}</p>
              <p className="sub-title">{mainItem?.description_text}</p>
              <MoreBtn
                title={"자세히 알아보기"}
                onClickBtn={() => {
                  window.open(mainItem?.url);
                }}
              />
            </div>
          </div>
        </div>

        <div className="youtube-bottom">
          <ul className="category-list">
            {list.map((value, idx) => {
              return (
                <li className="category-item" key={idx}>
                  <CategorySwiperWrap
                    categoryTitle={`${value?.title}`}
                    perView={4}
                    perGroup={1}
                    list={value?.board_list}
                    callback={(item) => {
                      setMainItem(item);
                      document.getElementById("best_video")?.scrollIntoView({ block: "center", behavior: "smooth" });
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default StoryYoutube;
