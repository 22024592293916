import Styles from "./styles";
import { useRef, useState } from "react";
import ui from "components/ui";
import { useCategory, useInquiry, useModals } from "hooks";

// img
import IconTel from "resources/image/icon/icon_tel_white.svg";
import IconKakao from "resources/image/icon/icon_kakao.svg";

function BottomQuickBar({ scroll }) {
  const modalOption = useModals();

  const [select, setSelect] = useState({ title: "" });
  const [selectOpen, setSelectOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const [tel, setTel] = useState("");

  const isLoad = useRef(false);

  const categoryInfo = useCategory({
    listOption: {
      type: 8,
      parent_info_id: 57,
    },
  });

  const inquiryInfo = useInquiry();

  const errMessage = (type) => {
    switch (type) {
      case 1:
        return "사건 유형을 선택해 주세요.";
      case 2:
        return "연락처를 입력해 주세요.";
      case 3:
        return "개인정보 수집 및 이용에 동의해 주세요.";
      default:
        return;
    }
  };

  const doValidate = async () => {
    if (select == null || select.title == "") {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = errMessage(1);
        return { ...e };
      });
    } else if (tel.length == 0) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = errMessage(2);
        return { ...e };
      });
    } else if (agree == false) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = errMessage(3);
        return { ...e };
      });
    } else {
      if (isLoad.current) return;
      isLoad.current = true;
      let result = await inquiryInfo.doInquiry(select.id, tel);
      if (result.success == true) {
        setTel("");
        setAgree(null);
        setSelect("");

        modalOption.setTermsModal((e) => {
          e.show = true;
          try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "popup_opened",
              modalName: "termsModal",
            });
          } catch (e) {
            console.log(e);
          }
          return { ...e };
        });
        setTimeout(() => {
          isLoad.current = true;
        }, 1000);
      } else {
        isLoad.current = false;
        alert("잠시 뒤 다시 시도해주세요");
      }
    }
  };

  return (
    <Styles.Container className={scroll ? "active" : ""}>
      <a href="tel:1670-5901" className="tel">
        <div className="icon">
          <img src={IconTel} alt="IconTel" />
        </div>

        <div className="textWrap">
          <div>1670-5901</div>
          <div>24시 전화 상담</div>
        </div>
      </a>

      <div className="quickBarWrap">
        <a href="https://pf.kakao.com/_UHiqxj" className="kakaoLink">
          <div className="icon">
            <img src={IconKakao} alt="IconKakao" />
          </div>
          카톡 상담
        </a>

        <div className="selectWrap">
          <label>사건유형</label>

          <div className="select">
            <div
              className={
                "nowSelect" +
                (select?.title !== "" ? " on" : "") +
                (selectOpen ? " open" : "")
              }
              onClick={() => {
                setSelectOpen(!selectOpen);
              }}
            >
              {select?.title === ""
                ? "사건유형을 선택해 주세요."
                : select.title}
            </div>

            {selectOpen && (
              <ul>
                {categoryInfo.list.map((item, index) => {
                  return (
                    <li
                      key={"selectList" + index}
                      onClick={() => {
                        setSelect(item);
                        setSelectOpen(false);
                      }}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="inputWrap">
          <label>연락처</label>
          <ui.input.Input
            placeholder="숫자만 입력해 주세요."
            value={tel}
            setValue={setTel}
            onChange={(e) => {
              let text = e.target.value;
              text = text.replace(/[^0-9]/gi, "");
              text = text.replace(/-/gi, "");
              text =
                text?.substr(0, 3) +
                (text?.length > 3
                  ? "-" +
                    text?.substr(3, 4) +
                    (text?.length > 7 ? "-" + text?.substr(7, 4) : "")
                  : "");
              setTel(text);
            }}
          />
        </div>

        <div className="checkboxWrap">
          <ui.input.Checkbox
            labelText="개인정보 수집 및 이용에 동의합니다."
            id={"agree_box"}
            checked={agree}
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
          />
          <button
            type="button"
            onClick={() => {
              modalOption.setDefaultModal((e) => {
                e.show = true;
                e.title = "개인정보보호를 위한 이용자 동의 사항";
                e.dec =
                  '1. 개인정보의 수집 및 이용 목적 : 문의에 대한 답변<br/>2. 수집항목 : 연락처, 이메일<br/>3. 개인정보의 보유 및 이용기간 : 이용목적이 <br class="mbBr"/>달성된 때까지 <br class="pcBr"/>보유합니다.';

                return { ...e };
              });
            }}
          >
            전문보기
          </button>
        </div>

        <button type="button" className="submitButton" onClick={doValidate}>
          비밀 상담 신청하기
        </button>
      </div>
    </Styles.Container>
  );
}

export default BottomQuickBar;
