import SearchInput from "components/layout/SearchInput";
import SearchResultList from "components/layout/SearchResultList";
import { EXPERT_DATA, ICON } from "constant";
import { useBoard } from "hooks";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import utils from "utils";

const IntroExpert = () => {
  
  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      keyword: param.keyword ? param.keyword : "",
      parent_category_info_id: "",
      category_info_id: "108",
      type: 1,
      recordSize: 12,
    };
    setFilterInfo({ ...f });
  }, [location]);

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <section className="section expert">
      <div className="expert-wrap">
        <div className="expert-search">
          <SearchInput placeholder={"전문가 검색"} />
        </div>
        <SearchResultList 
          data={list || EXPERT_DATA} 
          link={"/intro/expert/detail/"} 
          pagination={boardInfo.listQuery?.data?.pagination}
        />
      </div>
    </section>
  );
};

export default IntroExpert;
