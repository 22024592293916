import { usePopup } from "hooks";
import {
  MainBanner,
  MainExample,
  MainPressLawyer,
  MainLawyerList,
  MainProcess,
  MainReview,
  MainYoutube,
  MainCounsel,
  MainDirection,
} from "./components";

export default function MainPage() {
  const popupInfo = usePopup({
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "115",
      type: 10,
      recordSize: 10,
    },
  });

  return (
    <>
      <MainBanner />
      <MainExample />
      <MainPressLawyer />
      <MainLawyerList />
      <MainProcess />
      <MainReview />
      <MainYoutube />
      <MainCounsel />
      <MainDirection />
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {[
          "#성범죄전문변호사",
          "#성범죄변호사",
          "#강제추행변호사",
          "#성추행변호사",
          "#성폭행변호사",
          "#성폭행",
          "#강간죄",
          "#강간",
          "#준강간죄",
          "#준강간",
          "#성추행",
          "#강제추행죄",
          "#강제추행",
          "#준강제추행",
          "#성폭력",
          "#아청법",
          "#성매매",
          "#통매음",
          "#딥페이크처벌",
          "#디지털성범죄",
          "#카촬죄",
          "#카메라등이용촬영죄",
          "#도촬죄",
          "#검사출신변호사",
          "#법률상담",
          "#변호사상담",
          "#전관변호사",
          "#변호사선임",
          "#서울성범죄변호사",
          "#서울성범죄전문변호사",
          "#인천성범죄변호사",
          "#인천성범죄전문변호사",
          "#수원성범죄변호사",
          "#수원성범죄전문변호사",
          "#안산성범죄변호사",
          "#안산성범죄전문변호사",
          "#성범죄전문법무법인",
          "#성범죄법무법인",
          "#강제추행법무법인",
          "#성추행법무법인",
          "#성폭행법무법인",
          "#검사출신법무법인",
          "#법무법인상담",
          "#전관법무법인",
          "#법무법인선임",
          "#서울성범죄법무법인",
          "#서울성범죄전문법무법인",
          "#인천성범죄법무법인",
          "#인천성범죄전문법무법인",
          "#수원성범죄법무법인",
          "#수원성범죄전문법무법인",
          "#안산성범죄법무법인",
          "#안산성범죄전문법무법인",
          "#성범죄전문로펌",
          "#성범죄로펌",
          "#강제추행로펌",
          "#성추행로펌",
          "#성폭행로펌",
          "#검사출신로펌",
          "#로펌상담",
          "#전관로펌",
          "#로펌선임",
          "#서울성범죄로펌",
          "#서울성범죄전문로펌",
          "#인천성범죄로펌",
          "#인천성범죄전문로펌",
          "#수원성범죄로펌",
          "#수원성범죄전문로펌",
          "#안산성범죄로펌",
          "#안산성범죄전문로펌",
        ].map((tag) => (
          <li
            key={tag}
            style={{
              backgroundColor: "white",
              color: "white",
              fontSize: "14px",
            }}
          >
            {tag}
          </li>
        ))}
      </ul>
    </>
  );
}
