import { useState, useEffect, useRef } from "react";
import Styles from "./styles";
import { useSite } from "hooks";
import { IMAGEPATH } from "service/connector";

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import IconQuotes from "resources/image/icon/quotes_img.svg";

function MainReview() {
  const elementRef = useRef(null);
  const [acitve, setAcitve] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const siteInfo = useSite({
    listOption: {
      board_type: 4,
      content_type: 4,
      type: 2,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setAcitve(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Styles.MainReview ref={elementRef} className={acitve ? "active" : ""}>
      <div className="review_inner">
        <div
          className="tab_contents"
          onClick={() => {
            window.location.href = "/story/review/detail/" + list[tabActive].id;
          }}
        >
          <img src={IMAGEPATH + list[tabActive]?.image} alt="review" />
          <div className="tab_text_box">
            <p className="tit">{list[tabActive]?.title}</p>
            <p className="dec">
              {list[tabActive]?.description_text?.slice(0, 100)}
            </p>
          </div>
        </div>
        <div className="tab_menu">
          <div className="review_tit">
            <h2>의뢰인 실제 후기</h2>
          </div>
          <div className="handwriting">
            <img src={IconQuotes} alt="" />
            <p>
              온강 덕분에 앞으로 계속 잘 살아나갈 수 있는 <br /> 용기가
              생겼어요.
            </p>
            <p className="quot">-의뢰인의 자필 후기 中-</p>
          </div>
          <div className="tab_btn">
            {(list?.slice(0, 3) || [])?.map((item, index) => {
              return (
                <li
                  className={tabActive === index ? "active" : ""}
                  onClick={() => {
                    setTabActive(index);
                  }}
                  key={"tabList" + index}
                >
                  <img src={IMAGEPATH + item.image} alt="" />
                </li>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="btn_more"
        onClick={() => {
          window.location.href = "/story/review";
        }}
      >
        더보기
        <img src={IconPlus} alt="more view" />
      </button>
    </Styles.MainReview>
  );
}

export default MainReview;
