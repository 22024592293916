import ArticleHeading from "components/layout/ArticleHeading";
import { GridList } from "components/layout/GridList";
import StorySwiperWrap from "components/layout/StorySwiperWrap";
import { STORY_SWIPER_LIST, STORY_COLUMN_LIST } from "constant";
import { useBoard } from "hooks";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import utils from "utils";

const StoryReview = () => {

  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      parent_category_info_id: "",
      category_info_id: "",
      type: 4,
      recordSize: 9,
    };
    setFilterInfo({ ...f });
  }, [location]);

  const boardInfo = useBoard({
    listOption: filterInfo
  });

  const boardBestInfo = useBoard({
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "",
      hold_yn:"Y",
      type: 4,
      recordSize: 9,
    }
  });

  const bestList = boardBestInfo.listQuery?.data?.list || [];
  const list = boardInfo.listQuery?.data?.list || [];
  

  return (
    <section className="section story review full">
      <div className="review-wrap">
        <div className="review-top">
          <ArticleHeading title={"베스트 후기"} />
          <StorySwiperWrap perView={4} list={bestList || STORY_SWIPER_LIST}  />
        </div>

        <div className="review-bottom">
          <GridList list={list || STORY_COLUMN_LIST} pagination={boardInfo.listQuery?.data?.pagination} />
        </div>
      </div>
    </section>
  );
};

export default StoryReview;
